import {Component, OnDestroy} from '@angular/core';
import {JhiEventManager, JhiAlertService} from 'ng-jhipster';
import {Subscription} from 'rxjs';
import {AlertService} from './alert.service';
import {AuthServerProvider} from '../auth/auth-jwt.service';
import {Principal} from '../auth/principal.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-alert-error',
  template: `
    <div class="alerts" role="alert">
      <!--<div *ngFor="let alert of alerts" class="{{alert.position}}" [ngClass]="{\'toast\': alert.toast}">-->
      <div class="top right toast">
        <alert *ngFor="let alert of alerts" [type]="alert.type" class="alert alert-{{alert.type}}" (close)="alert.close(alerts)">
          <a class="closeButton clickable" style="background: transparent; border:  1px solid #fff; color: #fff;">
            <i class="ft-x" (click)="alert.close(alerts)" style="color: #fff;"></i></a>
          <span [innerHTML]="alert.msg"></span>
        </alert>
      </div>
    </div>`
})
export class AlertErrorComponent implements OnDestroy {

  alerts: any[];
  cleanHttpErrorListener: Subscription;

  // tslint:disable-next-line: no-unused-variable
  constructor(
    private jhiAlertService: JhiAlertService,
    private eventManager: JhiEventManager,
    private alertService: AlertService,
    private authServerProvider: AuthServerProvider,
    private principal: Principal,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.alerts = alertService.getAlerts();

    this.cleanHttpErrorListener = eventManager.subscribe('jhipsterApp.httpError', (response) => {
      let i;
      const httpErrorResponse = response.content;
      console.log(httpErrorResponse);
      switch (httpErrorResponse.status) {
        // connection refused, server not reachable
        case 0:
          this.addErrorAlert('Server not reachable', 'error.server.not.reachable');
          break;

        case 400:
          const arr = httpErrorResponse.headers.keys();
          let errorHeader = null;
          let entityKey = null;
          arr.forEach((entry) => {
            if (entry.startsWith('app-alert-type')) {
              entityKey = httpErrorResponse.headers.get(entry);
            } else if (entry.startsWith('app-alert')) {
              errorHeader = httpErrorResponse.headers.get(entry);
            }
          });
          if (errorHeader) {
            const entityName = entityKey;
            this.addErrorAlert(errorHeader, errorHeader, {entityName});
          } else if (httpErrorResponse.error !== '' && httpErrorResponse.error.fieldErrors) {
            const fieldErrors = httpErrorResponse.error.fieldErrors;
            for (i = 0; i < fieldErrors.length; i++) {
              const fieldError = fieldErrors[i];
              // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
              const convertedField = fieldError.field.replace(/\[\d*\]/g, '[]');
              const fieldName = convertedField.charAt(0).toUpperCase() +
                convertedField.slice(1);
              this.addErrorAlert(
                'Error on field "' + fieldName + '"', 'error.' + fieldError.message, {fieldName});
            }
          } else if (httpErrorResponse.error !== '' && httpErrorResponse.error.message) {
            if(httpErrorResponse.error.message === 'Invalid login') {
              this.authServerProvider.logout().subscribe(res => {
              });
              this.principal.authenticate(null);
              this.router.navigate(['/login']);
            }
            this.addErrorAlert(httpErrorResponse.error.message, httpErrorResponse.error.message, httpErrorResponse.error.params);
          } else {
            this.addErrorAlert(httpErrorResponse.error);
          }
          break;

        case 404:
          this.addErrorAlert('Not found', 'error.url.not.found');
          break;

        case 401:
          this.addErrorAlert('Email o password errate', 'error.unauthorized');
          this.authServerProvider.logout().subscribe(res => {
          });
          this.principal.authenticate(null);
          this.router.navigate(['/login']);
          break;


        default:
          console.log(httpErrorResponse);
          if (httpErrorResponse.error && httpErrorResponse.error !== '' && httpErrorResponse.error.message) {
            this.addErrorAlert(httpErrorResponse.error.message);
          } else {
            this.addErrorAlert(httpErrorResponse.error);
          }
      }
    });
  }

  ngOnDestroy() {
    if (this.cleanHttpErrorListener !== undefined && this.cleanHttpErrorListener !== null) {
      this.eventManager.destroy(this.cleanHttpErrorListener);
      this.alerts = [];
    }
  }

  addErrorAlert(message, key?, data?) {
    this.toastr.error(message, 'Error');
    /*this.alertService.addAlert(
      this.jhiAlertService.addAlert(
        {
          type: 'danger',
          msg: message,
          timeout: 5000,
          toast: this.jhiAlertService.isToast(),
          scoped: true,
          position: 'top right'
        },
        this.alerts
      )
    );*/
  }
}
