import {Routes, RouterModule} from '@angular/router';

// Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [
    {
        path: 'login',
        loadChildren: () => import('../modules/login/login.module').then(m => m.LoginModule)

    },
    {
        path: 'welcome',
        loadChildren: () => import('../modules/welcome/welcome.module').then(m => m.WelcomeModule)

    },
    {
        path: 'new-password',
        loadChildren: () => import('../modules/new-password/new-password.module').then(m => m.NewPasswordModule)

    },
    {
        path: 'home',
        loadChildren: () => import('../modules/complete-registration/complete-registration.module').then(m => m.CompleteRegistrationModule)

    },
    {
        path: 'video',
        loadChildren: () => import('../modules/video/video.module').then(m => m.VideoModule)

    },
];
