import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from './layouts/full/full-layout.component';

import {ContentLayoutComponent} from './layouts/content/content-layout.component';
import {FULL_ROUTES} from './routes/full-layout.routes';
import {CONTENT_ROUTES} from './routes/content-layout.routes';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: FULL_ROUTES },
  { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(
    appRoutes,
    {
      enableTracing: false,
      anchorScrolling: 'enabled',// <-- debugging purposes only
    }
  )],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
