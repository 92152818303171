import {Component, ElementRef, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {LoadingService} from './block/interceptor/loading.service';
import {DeviceDetectorService} from 'ngx-device-detector';

import 'core-js/es/string';
import 'core-js/es/array';
import 'core-js/es/map';
import {JhiEventManager} from 'ng-jhipster';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {} from './app.constants'
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  @ViewChild('template')
  public template: ElementRef;
  modalRef: BsModalRef;
  @Output() eventManager = new JhiEventManager;

  constructor(
    private loadingService: LoadingService,
    private deviceService: DeviceDetectorService,
    private modalService: BsModalService,

  ) {

  }

  ngOnInit() {
    const browser = this.deviceService.getDeviceInfo();
    const version = parseInt(browser.browser_version, 10);

    console.log(browser.browser);
    console.log(browser.browser_version);
    console.log(version);
    let ok = false;

    if (browser.browser.toLowerCase() === 'chrome' || browser.browser === 'Chrome') {
      // ok = version >= 45;
      ok = version >= 45;
    } else if (browser.browser.toLowerCase() === 'firefox') {
      ok = version >= 38;
    } else if (browser.browser.toLowerCase() === 'safari' || browser.browser === 'Safari') {
      ok = version >= 9;
    } else if (browser.browser.toLowerCase() === 'opera') {
      ok = version >= 30;
    } else if (browser.browser.toLowerCase() === 'edge') {
      ok = version >= 12;
    } else if (browser.browser.toLowerCase() === 'ms-edge-chromium') {
      ok = true;
    } else if (browser.browser.toLowerCase() === 'ie') {
      ok = version >= 11;
    }






    console.log(ok);
    if(!ok) {
      this.openModal();
    }
  }

  openModal() {
    Swal.fire({
      title: 'Il browser che stai utilizzando non è supportato\n' +
          '    si consiglia di utilizzare le ultime versioni di Chrome, Firefox, Safari, Edge.',
      confirmButtonText: 'OK',
      icon: 'warning',
      confirmButtonColor:'#F6030A',
    })
    //this.modalRef = this.modalService.show(this.template, {class: 'modal-sm', backdrop: true, ignoreBackdropClick: true,});
  }

  isLoading() {
    return this.loadingService.isLoading();
  }
}
